<template>
    <div class="main">
		<div class="logo_wrap">
			<img src="../../assets/logo.png" class="logo" alt="">
		</div>
		<div class="line"></div>
		<p class="text">布点达将车间数字化 </p>
		<p class="text">提供线上下单系统以及订单跟踪管理功能</p>
		<div :class="['phone',{'to':isShow}]">
			<img src="../../assets/phone.png" class="bg_phone" alt="phone">
		</div>
		<div class="fixed bottom">
			<a href="https://itunes.apple.com/cn/app/id1599196561" title="ios下载" class="button ios_download" target="_blank"><span class="icon"></span>IOS版本下载</a>
			<a href="http://dl.wwitechs.com/budala/bdl.apk"  download="布达啦" title="安卓下载" class="button anzhuo_download"><span class="icon" target="_blank"></span>Android版下载</a>
		</div>
		<div :class="['copyRight_m',{'open':isOpen}]">
			<a href="javascript:void(0);" class="copyright_btn" @click="openCopy">
				<img src="../../assets/icon_down.png" :class="['icon_down',{'open':isOpen}]" alt="上拉">
			</a>
			<div class="copyright_content">
				<p>Copyright 2019 WWI Co.,Ltd.保留所有权利。</p>
				<p><a href="https://beian.miit.gov.cn/" target="_blank" title="浙ICP备15045777号" style="color:  #767e85;">浙ICP备15045777号</a> </p>
				<p>版权所有：浙江水木物联技术有限公司</p>
			</div>
		</div>
		<div id="forhorview">推荐使用竖屏浏览哦~</div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
              isShow:false,
			  isOpen:false
            }
        },
        mounted() {
			setTimeout(()=>{
				this.isShow = true
			},500)
            this.viewport()
            this.$nextTick(() => {
                this.initScreen()
            })
        },
        methods: {
            viewport() {
                var o = $(window),
                    i = $("html"),
                    e = $("#forhorview"),
                    t = function() {
                        i.css("font-size", document.documentElement.clientWidth / 375 * 312.5 + "%"),
                            90 == o[0].orientation || -90 == o[0].orientation ? e.addClass("show") : e.removeClass(
                                "show")
                    };
                t(), o.on("onorientationchange" in window ? "orientationchange" : "resize", function() {
                    setTimeout(t, 200)
                })
            },
            initScreen(callback) {
                document.getElementsByTagName('html')[0].style.fontSize = document.documentElement.clientWidth / 375 *
                    312.5 + "%" //长页面时使用,不缩放
                if (callback) callback();
            },
			openCopy(){
				this.isOpen = !this.isOpen;
			}
        }
    }
</script>
